import React from "react";
import lodashGet from "lodash/get";

import PriceCurrenyField from "@/features/input/PriceCurrenyField";
import {
  ContractCurrency,
  MAX_MILESTONE_VALUE,
  MIN_MILESTONE_VALUE,
} from "@/services/ContractsService";
import { Body } from "@/components/Typography";

import { ERRORS, useMilestoneForm } from "../../MilestoneForm.config";

const MilestonePriceInput: React.FC<{
  className?: string;
}> = ({ className }) => {
  const { values, setFieldValue, setFieldTouched, touchedAndHasError, errors } =
    useMilestoneForm();

  const fieldKey = `value`;
  const value = lodashGet(values, fieldKey, "");
  const showError = touchedAndHasError(fieldKey);
  const priceError = lodashGet(errors, fieldKey, "");

  return (
    <>
      <PriceCurrenyField
        className={className}
        // TO-CHECK: Remove hardcode USD currency
        currency={ContractCurrency.USD}
        onCurrencyChange={() => {}}
        price={value || 0}
        onPriceChange={(value) => {
          setFieldValue(fieldKey, +value);
        }}
        priceFieldProps={{
          onBlur: () => {
            setFieldTouched(fieldKey);
          },
          hasError: showError,
          min: MIN_MILESTONE_VALUE,
          max: MAX_MILESTONE_VALUE,
        }}
      />

      {showError && (
        <>
          {priceError === ERRORS.MIN_PRICE_ERROR && (
            <Body
              className="mt-2 color-error"
              size="md"
              style={{ paddingLeft: "2rem" }}
            >
              Price cannot be less than {MIN_MILESTONE_VALUE} USD
            </Body>
          )}

          {priceError === ERRORS.MAX_PRICE_ERROR && (
            <Body
              className="mt-2 color-error"
              size="md"
              style={{ paddingLeft: "2rem" }}
            >
              Price cannot be more than {MAX_MILESTONE_VALUE} USD
            </Body>
          )}
        </>
      )}
    </>
  );
};

export default MilestonePriceInput;
