import React from "react";

import Icon from "@/components/misc/Icon";
import TextField from "@/components/input/TextField";
import { appFeatures } from "@/config/services";

import { useContractForm } from "../../OfferUpdateForm.config";
import { StyledSmall } from "../../OfferUpdateForm.styles";
import {
  CLIENT_NAME_CHAR_LIMIT,
  CLIENT_NAME_OPTIONAL,
} from "../../OfferUpdateForm.config";

const ClientNameInput: React.FC<{ className?: string }> = ({ className }) => {
  const { values, setFieldValue, setFieldTouched, touched } = useContractForm();

  const charactersLeft = Math.max(
    0,
    CLIENT_NAME_CHAR_LIMIT - (values.client_name?.length || 0)
  );

  if (!appFeatures.isSupported("OFFER.FORM.CLIENT")) {
    return null;
  }

  return (
    <>
      <TextField
        prependContent={<Icon isSrcRelative src="user_2.svg" size="xs" />}
        className={className}
        placeholder="Client Name"
        value={values.client_name}
        onChange={(value) => {
          if (value.length > CLIENT_NAME_CHAR_LIMIT) {
            value = value.substring(0, CLIENT_NAME_CHAR_LIMIT);
          }

          setFieldValue("client_name", value);
        }}
        onBlur={() => {
          setFieldTouched("client_name");
        }}
        hasError={
          !CLIENT_NAME_OPTIONAL && !!touched.client_name && !values.client_name
        }
        max={10}
      />
      <StyledSmall className="mt-2 mb-4">
        {charactersLeft} character left
      </StyledSmall>
    </>
  );
};

export default ClientNameInput;
