import React from "react";

import Button, { IButtonProps } from "@/components/input/Button";
import { ContractId, useDeleteOffer } from "@/services/ContractsService";

const DeleteOfferButton: React.FC<
  IButtonProps & { contractId: ContractId }
> = ({ contractId, children, ...buttonProps }) => {
  const { deleteOffer, isDeleting, jsx } = useDeleteOffer({
    contractId,
  });

  return (
    <>
      {jsx}
      <Button
        colorVariant="danger"
        {...buttonProps}
        disabled={isDeleting}
        onClick={deleteOffer}
      >
        {children || "Delete"}
      </Button>
    </>
  );
};

export default DeleteOfferButton;
