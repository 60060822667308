import React, { useEffect, useRef } from "react";

import { ContractMilestone } from "@/services/ContractsService";

import TransactionNameInput from "../inputs/TransactionNameInput";
import ClientNameInput from "../inputs/ClientNameInput";
import FilesInput from "../inputs/TransactionFilesInput";
import DescriptionField from "../inputs/TransactionDescriptionField";
import MilestoneInput from "../inputs/MilestoneInput";
import { useContractForm } from "../../OfferUpdateForm.config";

const MilestoneContractDetailsStep: React.FC = () => {
  const { values, setFieldValue } = useContractForm();

  const milestonesRef = useRef(values.milestones as ContractMilestone[]);

  useEffect(() => {
    const milestoneWithValues = milestonesRef.current.filter(
      (milestone) => !!Object.keys(milestone).length
    );
    setFieldValue("milestones", milestoneWithValues);
  }, [setFieldValue]);

  return (
    <>
      <TransactionNameInput />

      <ClientNameInput className="mt-3" />

      <DescriptionField className="mt-4" />

      <FilesInput className="mt-2" />

      <MilestoneInput className="mt-3" />
    </>
  );
};

export default MilestoneContractDetailsStep;
