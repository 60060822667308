import { useCallback, useState } from "react";

import { contractService } from "@/config/services";

import {
  AddContractMilestoneAttributes,
  ContractId,
} from "../ContractsService.types";

function useAddMilestone({ contractId }: { contractId: ContractId }) {
  const [isAdding, setIsAdding] = useState(false);

  // ----------------------------------

  const addMilestone = useCallback(
    (milestoneDetails: AddContractMilestoneAttributes) => {
      setIsAdding(true);

      return contractService
        .addMilestoneToContract({ contractId, milestoneDetails })
        .finally(() => {
          setIsAdding(false);
        });
    },
    [contractId]
  );

  // ----------------------------------

  return {
    isAdding,
    addMilestone,
  };
}

export default useAddMilestone;
