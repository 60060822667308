import { useCallback, useState } from "react";

import { contractService } from "@/config/services";

import {
  ContractId,
  MilestoneId,
  UpdateContractMilestoneAttributes,
} from "../ContractsService.types";

function useUpdateMilestone({
  milestoneId,
  contractId,
}: {
  milestoneId: MilestoneId;
  contractId: ContractId;
}) {
  const [isUpdating, setIsUpdating] = useState(false);

  // ----------------------------------

  const updateMilestone = useCallback(
    (milestoneDetails: UpdateContractMilestoneAttributes) => {
      setIsUpdating(true);

      return contractService
        .updateMilestone({ milestoneId, contractId, milestoneDetails })
        .finally(() => {
          setIsUpdating(false);
        });
    },
    [milestoneId, contractId]
  );

  // ----------------------------------

  return {
    isUpdating,
    updateMilestone,
  };
}

export default useUpdateMilestone;
