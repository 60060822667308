import React, { useCallback, useMemo } from "react";
import lodashGet from "lodash/get";

import { formatDate } from "@/utils/date";
import { Label } from "@/components/Typography";
import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";

import { useContractForm } from "../../../OfferUpdateForm.config";
import MilestoneDescriptionField from "../MilestoneDescriptionField";
import { StyledHeading, StyledTopContainer } from "./MilestoneCard.styles";

const MilestoneCard: React.FC<{
  milestoneIndex: number;
  className?: string;
  onDelete: (index: number) => void;
  onEdit: (index: number) => void;
  readOnly?: boolean;
}> = ({ milestoneIndex, className, onDelete, onEdit, readOnly }) => {
  const { values, touchedAndHasError } = useContractForm();
  const { milestones = [] } = values;

  const milestoneKey = `milestones[${milestoneIndex}]`;

  const name = lodashGet(values, `${milestoneKey}.name`, "");
  const fallbackName = `Milestone ${milestoneIndex + 1}`;
  const nameHasError = touchedAndHasError(`${milestoneKey}.name`);

  const value = lodashGet(values, `${milestoneKey}.value`, "");
  const valueHasError = touchedAndHasError(`${milestoneKey}.value`);

  const startDate = lodashGet(values, `${milestoneKey}.start_date`, "");
  const endDate = lodashGet(values, `${milestoneKey}.end_date`, "");

  const canDelete = milestones.length > 1 && !readOnly;

  const datesFormatted = useMemo(() => {
    const dateParts: string[] = [];

    if (startDate) {
      dateParts.push(formatDate(startDate, "MMM D"));
    }

    if (endDate) {
      dateParts.push(formatDate(endDate, "MMM D"));
    }

    return dateParts.join(" - ");
  }, [startDate, endDate]);

  //-----------------------

  const handleEdit = useCallback(() => {
    if (readOnly) {
      return;
    }

    onEdit(milestoneIndex);
  }, [onEdit, milestoneIndex, readOnly]);

  const handleDelete = useCallback(() => {
    if (readOnly) {
      return;
    }

    onDelete(milestoneIndex);
  }, [onDelete, milestoneIndex, readOnly]);

  //-----------------------

  return (
    <div className={className}>
      <StyledTopContainer>
        <StyledHeading
          $hasError={nameHasError}
          size="md"
          as="div"
          onClick={handleEdit}
        >
          {name || fallbackName}
        </StyledHeading>
        <StyledHeading
          $hasError={valueHasError}
          size="md"
          as="div"
          onClick={handleEdit}
        >
          ${value}
        </StyledHeading>

        {canDelete && (
          <Button variant="ghost" onClick={handleDelete} className="p-0 px-2">
            <Icon isSrcRelative src="delete.svg" size="sm" />
          </Button>
        )}
      </StyledTopContainer>

      <Label
        onClick={handleEdit}
        size="md"
        style={{ color: "#666D80" }}
        className="mt-2"
      >
        {datesFormatted}
      </Label>

      <MilestoneDescriptionField
        disabled={!readOnly}
        readOnly={readOnly}
        className="mt-4"
        milestoneIndex={milestoneIndex}
        onClick={readOnly ? undefined : handleEdit}
      />
    </div>
  );
};

export default MilestoneCard;
