import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import isYesterday from "dayjs/plugin/isYesterday";
import isToday from "dayjs/plugin/isToday";
import localeData from "dayjs/plugin/localeData";

import { DATE_DISPLAY_FORMAT } from "@/config/data";
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(localeData);

dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a min",
    m: "a min",
    mm: "%d mins",
    h: "an hour",
    hh: "%d hours",
    d: "yesterday",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

export function formatDate(date: string | Date, format = DATE_DISPLAY_FORMAT) {
  if (!date) return "";

  date = new Date(date);
  const dateDayJs = dayjs(date);

  return dateDayJs.format(format);
}

export function dateToEpoch(date: string | Date) {
  date = new Date(date);
  return date.getTime();
}

export function getDateFromNowText(date: string | Date) {
  if (!date) return "";

  date = new Date(date);
  const dateDayJs = dayjs(date);
  const isYesterday = dateDayJs.isYesterday();
  const isToday = dateDayJs.isToday();

  return dateDayJs.fromNow(isYesterday || isToday);
}

export function getDateToNowText(date: string | Date) {
  if (!date) return "";

  date = new Date(date);
  const dateDayJs = dayjs(date);
  const isYesterday = dateDayJs.isYesterday();

  return dateDayJs.toNow(isYesterday);
}

export function isDateYesterday(date: string | Date) {
  if (!date) return false;

  date = new Date(date);
  const dateDayJs = dayjs(date);
  return dateDayJs.isYesterday();
}

export function isDateLargerThan(
  date: string | Date,
  toCompareDate: string | Date
) {
  if (!date) return false;

  if (!toCompareDate) return true;

  return new Date(date) > new Date(toCompareDate);
}

export function isDateSmallerThan(
  date: string | Date,
  toCompareDate: string | Date
) {
  if (!date) return false;

  if (!toCompareDate) return true;

  return new Date(date) < new Date(toCompareDate);
}

export function getClientTimezone() {
  try {
    return Intl.DateTimeFormat().resolvedOptions();
  } catch {
    return null;
  }
}

export function isDateToday(date: string | Date) {
  date = new Date(date);
  const dateDayJs = dayjs(date);

  return dateDayJs.isToday();
}

export function getMonthList() {
  return dayjs.monthsShort();
}

export function getCurrentMonth() {
  return dayjs().month();
}

export function getFormattedDuration(durationInMs = 0) {
  const duration = dayjs
    .duration(Math.abs(-durationInMs), "millisecond")
    .format("D[_days]:H[_hours]:m[_minutes]:s[_seconds]");

  const durationParts = duration.split(":").reduce((map, item) => {
    const [value, key] = item.split("_");
    return { ...map, [key]: +(value || "0") };
  }, {}) as {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  };

  const { days, hours, minutes, seconds } = durationParts;

  const formattedParts: string[] = [];

  if (days) {
    formattedParts.push(`${days} ${days > 1 ? "days" : "day"}`);
  }
  if (days || hours) {
    formattedParts.push(`${hours} ${hours > 1 ? "hours" : "hour"}`);
  }
  if (days || hours || minutes) {
    formattedParts.push(`${minutes} ${minutes > 1 ? "minutes" : "minute"}`);
  }
  if (seconds && !minutes && !formattedParts.length) {
    formattedParts.push("1 minute");
  }

  if (false) {
    if (days || hours || minutes || seconds) {
      formattedParts.push(`${seconds} ${seconds > 1 ? "seconds" : "second"}`);
    }
  }

  if (formattedParts.length === 1) {
    return formattedParts[0];
  }

  const lastPart = formattedParts.pop();
  let formatted = formattedParts.join(", ");
  if (lastPart) {
    formatted += ` and ${lastPart}`;
  }

  return formatted;
}
