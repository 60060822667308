import React from "react";

import { ContractType } from "@/services/ContractsService";

import OneTimeContractDetailsStep from "./OneTimeContractDetailsStep";
import MilestoneContractDetailsStep from "./MilestoneContractDetailsStep";
import HourlyContractDetailsStep from "./HourlyContractDetailsStep";
import { StyledBox } from "../../OfferUpdateForm.styles";
import { useContractForm } from "../../OfferUpdateForm.config";

const ContractDetailsStep: React.FC = () => {
  const { values } = useContractForm();

  const isOneTimeContract = values.contract_type === ContractType.OneTime;
  const isMilestoneContract = values.contract_type === ContractType.Milestone;
  const isHourlyContract = values.contract_type === ContractType.Hourly;

  if (isOneTimeContract) {
    return (
      <StyledBox>
        <OneTimeContractDetailsStep />
      </StyledBox>
    );
  }

  if (isMilestoneContract) {
    return (
      <StyledBox>
        <MilestoneContractDetailsStep />
      </StyledBox>
    );
  }

  if (isHourlyContract) {
    return (
      <StyledBox>
        <HourlyContractDetailsStep />
      </StyledBox>
    );
  }
};

export default ContractDetailsStep;
