"use client";

import styled from "@emotion/styled";

import ContractForm from "@/features/contracts/ContractForm";
import { screenLargerThan, screenSmallerThan } from "@/styles";

export const StyledContractForm = styled(ContractForm)`
  ${screenSmallerThan.tablet} {
    height: 100%;
  }

  ${screenLargerThan.tablet} {
    max-width: 46.5rem;
    margin-left: 5rem;
  }
`;

export const StyledInfoContainer = styled.div`
  margin: 1rem;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  max-width: 460px;
  margin: 0 auto;
  margin-top: 5rem;
  border-radius: 0.25rem;
`;
