"use client";
import React from "react";

import Form from "@/components/input/Form";

import { INITIAL_VALUES, VALIDATION_SCHEMA } from "./OfferUpdateForm.config";
import { OfferFormValues, IContractFormProps } from "./OfferUpdateForm.types";
import ContractDetailsStep from "./components/steps/ContractDetailsStep";
import { StyledSubmitButton } from "./OfferUpdateForm.styles";

const OfferUpdateForm: React.FC<IContractFormProps> = (props) => {
  const {
    initialValues: initialValuesFromProps = INITIAL_VALUES,
    onChange,
    className,
    submitButtonProps = {},
    onSubmit,
  } = props;

  return (
    <>
      <Form<OfferFormValues>
        onSubmit={onSubmit}
        yupValidationSchema={VALIDATION_SCHEMA}
        initialValues={initialValuesFromProps}
        onChange={onChange}
      >
        {({ context: { submitForm } }) => {
          return (
            <form className={className} onSubmit={submitForm}>
              <ContractDetailsStep />
              <StyledSubmitButton
                className="mt-4"
                {...submitButtonProps}
                type="submit"
              >
                {submitButtonProps.children || "Update Offer"}
              </StyledSubmitButton>
            </form>
          );
        }}
      </Form>
    </>
  );
};

export default OfferUpdateForm;
