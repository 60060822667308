import { createContext, useContext, useMemo, useState } from "react";

import {
  getNextActiveMilestone,
  useContractDetails,
  usePaymentStatus,
} from "@/services/ContractsService";
import { ContractPreviewLoading } from "@/features/contracts/ContractPreview";
import { useResponsive } from "@/styles";
import SplashScreen from "@/features/pages/app/SplashScreen";
import Contract404Page from "@/features/contracts/Contract404Page";
import Modal, { useModalState } from "@/components/misc/Modal";

import {
  IContractManagementContext,
  IContractManagementPageProps,
  Tab,
} from "./ContractManagementPage.types";
import { appFeatures } from "@/config/services";

const ContractManagementContext = createContext(
  {} as IContractManagementContext
);

export const useContractManagement = () =>
  useContext(ContractManagementContext);

export const ContractManagementProvider: React.FC<
  IContractManagementPageProps & { children?: React.ReactNode }
> = ({ children, layoutProps }) => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const { contractDetails, isLoading } = useContractDetails({
    redirectIfNotFound: !appFeatures.isSupported("CONTRACT.SHOW_404_PAGE"),
    useContractIdFromUrl: true,
  });

  const [activeTab, setActiveTab] = useState<string>(Tab.Overview);

  const modalState = useModalState({ isOpen: true });
  const showDisabledPage =
    contractDetails?.is_disabled || (!isLoading && !contractDetails);

  const nextActiveMilestoneId = useMemo(
    () =>
      contractDetails
        ? getNextActiveMilestone(contractDetails)?.milestone?.id || 0
        : 0,
    [contractDetails]
  );
  const { data: paymentStatus = null, isLoading: isPaymentStatusLoading } =
    usePaymentStatus({ milestoneId: nextActiveMilestoneId });

  if (isLoading || isPaymentStatusLoading) {
    return isScreenSmallerThanTablet ? (
      <SplashScreen />
    ) : (
      <ContractPreviewLoading />
    );
  }

  if (showDisabledPage || !contractDetails) {
    return (
      <Modal
        fullscreen
        state={modalState}
        contentContainerProps={{ style: { padding: "0" } }}
      >
        <Contract404Page />
      </Modal>
    );
  }

  const isBankAccountPayment = !!paymentStatus?.payment?.isBankAccountPayment;
  const paymentCompleted = !!paymentStatus?.payment?.paymentCompleted;
  const disableControls = isBankAccountPayment && !paymentCompleted;

  return (
    <ContractManagementContext.Provider
      value={{
        contractDetails,
        activeTab,
        setActiveTab,
        layoutProps,
        paymentStatus,
        disableControls,
      }}
    >
      {children}
    </ContractManagementContext.Provider>
  );
};
