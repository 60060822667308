import React from "react";

import { ContractType } from "@/services/ContractsService";

import { useContractForm } from "../../OfferUpdateForm.config";
import { StyledBoldTextField } from "../../OfferUpdateForm.styles";

const TransactionNameInput: React.FC<{ className?: string }> = ({
  className,
}) => {
  const { values, setFieldValue, touched, setFieldTouched } = useContractForm();

  const isOneTimeContract = values.contract_type === ContractType.OneTime;

  return (
    <StyledBoldTextField
      className={className}
      hasError={!!touched.title && !values.title}
      placeholder="Job Name"
      value={values.title}
      onBlur={() => {
        setFieldTouched("title");

        if (isOneTimeContract) {
          setFieldTouched("milestones[0].name");
        }
      }}
      onChange={(value) => {
        setFieldValue("title", value);

        if (isOneTimeContract) {
          setFieldValue("milestones[0].name", value);
        }
      }}
      style={
        values.title
          ? {}
          : ({
              "--color": "#a4acb9",
            } as React.CSSProperties)
      }
    />
  );
};

export default TransactionNameInput;
