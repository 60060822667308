"use client";
import React from "react";

import Form from "@/components/input/Form";

import { INITIAL_VALUES, VALIDATION_SCHEMA } from "./MilestoneForm.config";
import {
  MilestoneFormValues,
  IMilestoneFormProps,
} from "./MilestoneForm.types";
import MilestoneNameInput from "./components/inputs/MilestoneNameInput";
import MilestonePriceInput from "./components/inputs/MilestonePriceInput";
import MilestoneDateInput from "./components/inputs/MilestoneDateInput";
import MilestoneDescriptionField from "./components/inputs/MilestoneDescriptionField";
import Button from "@/components/input/Button";

const MilestoneForm: React.FC<IMilestoneFormProps> = (props) => {
  const {
    initialValues = INITIAL_VALUES,
    onChange,
    onSubmit,
    milestoneIndex,
    className,
    submitButtonProps = {},
  } = props;

  return (
    <>
      <Form<MilestoneFormValues, { milestoneIndex?: number }>
        onSubmit={onSubmit}
        yupValidationSchema={VALIDATION_SCHEMA}
        initialValues={initialValues}
        onChange={onChange}
        extraFields={{ milestoneIndex }}
      >
        {({ context: { submitForm } }) => (
          <form className={className} onSubmit={submitForm}>
            <MilestoneNameInput />
            <MilestonePriceInput className="mt-2" />
            <MilestoneDateInput className="mt-2" />
            <MilestoneDescriptionField className="mt-4" />
            <Button
              className=" w-100 mt-4"
              {...submitButtonProps}
              type="submit"
            >
              {submitButtonProps.children || "Add milestone"}
            </Button>
          </form>
        )}
      </Form>
    </>
  );
};

export default MilestoneForm;
