import React from "react";

import Button, { IButtonProps } from "@/components/input/Button";
import {
  ContractId,
  MilestoneId,
  useDeleteMilestone,
} from "@/services/ContractsService";
import { appFeatures } from "@/config/services";

const DeleteMilestoneButton: React.FC<
  IButtonProps & { contractId: ContractId; milestoneId: MilestoneId }
> = ({ contractId, milestoneId, children, ...buttonProps }) => {
  const { deleteMilestone, isDeleting, jsx } = useDeleteMilestone({
    contractId,
    milestoneId,
  });

  if (!appFeatures.isSupported("CONTRACT.DELETE_JOB_MILESTONE")) {
    return null;
  }

  return (
    <>
      {jsx}
      <Button
        variant="secondary"
        colorVariant="danger"
        {...buttonProps}
        disabled={isDeleting}
        onClick={deleteMilestone}
      >
        {children ||
          (isDeleting ? "Deleting milestone..." : "Delete milestone")}
      </Button>
    </>
  );
};

export default DeleteMilestoneButton;
