import uniqBy from "lodash/uniqBy";
import lodashGet from "lodash/get";

import { store } from "@/services/Store";
import { isDateLargerThan, isDateSmallerThan } from "@/utils/date";
import Share, { ShareContent } from "@/services/Share";

import {
  ContractCompleteDetails,
  ContractCreatorType,
  ContractMetaData,
  ContractParticipant,
  ContractStatus,
  CreatedContractDetails,
  MilestoneCompleteDetails,
} from "../ContractsService.types";
import {
  getNextFundableMilestone,
  getNextReviewableMilestone,
  getNextSubmittableMilestone,
  isMilestoneCompleted,
  isMilestoneFunded,
} from "./milestone";
import { getOfferPageUrl } from "./routing";

export function getContractPartipants(data: ContractCompleteDetails) {
  let participants =
    data.participants ||
    (data.milestones || []).flatMap((milestone) => milestone.participants);
  participants = uniqBy(participants, "id");

  return participants;
}

export function contractModelToCompleteDetails(
  data: CreatedContractDetails
): ContractCompleteDetails {
  const updatedData = {
    ...data,
    rejectedBy: lodashGet(data, "rejectedBy", null),
    acceptedBy: lodashGet(data, "acceptedBy", null),
    history: lodashGet(data, "history", []),
    files: lodashGet(data, "files", []),
    milestones: lodashGet(data, "milestones", []).filter(
      (milestone: MilestoneCompleteDetails) => {
        return !milestone.is_deleted;
      }
    ),
  } satisfies ContractCompleteDetails;

  return updatedData;
}

export function sanitizeContractCompleteDetails(
  data: Partial<ContractCompleteDetails>
): ContractCompleteDetails {
  const updatedData = {
    ...data,
    participants: getContractPartipants(data as ContractCompleteDetails),
    milestones: lodashGet(data, "milestones", []).filter(
      (milestone: MilestoneCompleteDetails) => {
        return !milestone.is_deleted;
      }
    ),
  } as ContractCompleteDetails;

  return updatedData;
}

export function getContractPayee(data: ContractCompleteDetails) {
  const payee =
    (data.participants || []).find(
      (p) => p.role === ContractCreatorType.Payee
    ) || null;

  return payee;
}

export function getContractPayer(data: ContractCompleteDetails) {
  const payee =
    (data.participants || []).find(
      (p) => p.role === ContractCreatorType.Payer
    ) || null;

  return payee;
}

export function getContractOtherParticipant(data: ContractCompleteDetails) {
  const loggedInUser = store.getState().auth.userData;

  if (!loggedInUser) return null;

  const otherUser =
    (data.participants || []).find((p) => p.user_id !== loggedInUser.id) ||
    null;

  return otherUser;
}

export function amIContractOwner(data: ContractCompleteDetails) {
  const loggedInUser = store.getState().auth.userData;

  if (!loggedInUser) return false;

  return data.owner_id === loggedInUser.id;
}

export function getContractParticipant(
  data: ContractCompleteDetails,
  userType: ContractCreatorType
) {
  if (userType === ContractCreatorType.Payee) return getContractPayee(data);

  if (userType === ContractCreatorType.Payer) return getContractPayer(data);

  return null;
}

export function amIContractParticipant(data: ContractCompleteDetails) {
  const loggedInUser = store.getState().auth.userData;

  if (!loggedInUser) return false;

  const isParticipant = !!(data.participants || []).find(
    (p) => p.user_id === loggedInUser.id
  );

  return isParticipant;
}

export function amIContractPayer(data: ContractCompleteDetails) {
  const loggedInUser = store.getState().auth.userData;

  if (!loggedInUser) return false;

  const payer = getContractPayer(data);

  return payer?.user_id === loggedInUser.id;
}

export function amIContractPayee(data: ContractCompleteDetails) {
  const loggedInUser = store.getState().auth.userData;

  if (!loggedInUser) return false;

  const payer = getContractPayee(data);

  return payer?.user_id === loggedInUser.id;
}

export function getLoggedInUserContractUserType(data: ContractCompleteDetails) {
  const userType = amIContractPayee(data)
    ? ContractCreatorType.Payee
    : amIContractPayer(data)
    ? ContractCreatorType.Payer
    : null;

  return userType;
}

export function isContractApproved(data: ContractCompleteDetails) {
  let isApproved = data.status !== ContractStatus.PendingFunding;

  if (data.milestones.length) {
    isApproved = data.milestones.some(isMilestoneFunded);
  }

  return isApproved;
}

export function isContractCompleted(
  data: ContractCompleteDetails,
  checkMilestonesAlso = true
) {
  if (checkMilestonesAlso && data?.milestones?.length) {
    return data.milestones.every(isMilestoneCompleted);
  }

  return data.status === ContractStatus.Completed;
}

export function filterOutBrokenContracts(list: ContractCompleteDetails[]) {
  const filtered = list
    .filter((c) => (amIContractPayer(c) ? isContractApproved(c) : true))
    .filter((contract) =>
      !!contract.participants.length ? amIContractParticipant(contract) : true
    );

  return filtered;
}

export function getParticipantFullName(participant: ContractParticipant) {
  const fullname = [
    participant?.user?.given_name || "",
    participant?.user?.family_name || "",
  ]
    .filter(Boolean)
    .join(" ");

  return fullname;
}

export function getContractTotalValue(contract: ContractCompleteDetails) {
  const { total_value, milestones } = contract;

  return (
    total_value ||
    milestones.reduce((total, { value }) => total + +(value || "0"), 0)
  );
}

export function isContractAnOffer(contract: ContractCompleteDetails) {
  return !isContractApproved(contract);
}

export function isContractAJob(contract: ContractCompleteDetails) {
  return isContractApproved(contract);
}

export function getContractMetaData(
  contract: ContractCompleteDetails
): ContractMetaData {
  const isAuthenticated = !!store.getState().auth.userData;

  const amIPayer = amIContractPayer(contract);
  const amIPayee = amIContractPayee(contract);

  const amIParticipant = amIContractParticipant(contract);
  const isApproved = isContractApproved(contract);
  const isCompleted = isContractCompleted(contract);
  const nextSubmittableMilestone =
    getNextSubmittableMilestone(contract).milestone;
  const nextReviewableMilestone =
    getNextReviewableMilestone(contract).milestone;
  const nextFundableMilestone = getNextFundableMilestone(contract).milestone;

  const canManage = isApproved && amIParticipant && isAuthenticated;
  const canApprove = !isApproved && (isAuthenticated ? !amIPayee : true);
  const canPreview = !isApproved && (isAuthenticated ? amIParticipant : true);

  return {
    amIPayer,
    amIPayee,
    isApproved,
    isCompleted,
    nextSubmittableMilestone,
    nextReviewableMilestone,
    nextFundableMilestone,
    //----------------
    canManage,
    canApprove,
    canPreview,
  };
}

export function getContractDuration(contract: ContractCompleteDetails) {
  let minDate: any = null;
  let maxDate: any = null;

  let dates = [contract.created_at];

  (contract?.milestones || []).forEach(({ start_date, end_date }) => {
    if (start_date) dates.push(start_date);
    if (end_date) dates.push(end_date);
  });

  dates = dates.filter(Boolean);

  dates.forEach((date) => {
    if (isDateLargerThan(date, maxDate)) {
      maxDate = date;
    }
    if (isDateSmallerThan(date, minDate)) {
      minDate = date;
    }
  });

  if (minDate === maxDate) {
    maxDate = null;
  }

  return {
    minDate,
    maxDate,
  };
}

//--------------------

export function getOfferShareContent({
  contractId,
}: {
  contractId: string;
}): ShareContent {
  return {
    title: "I've sent you an offer with Paypipe.",
    url: Share.getShareUrl(getOfferPageUrl(contractId)),
    text: `Here's the offer I created for you. If you're happy with it, accept and fund the job and I'll get to work.\n\n Paypipe provides secure and safe transactions globally between freelancers and their clients.`,
  };
}

export function getContractCompletionDate(contract: ContractCompleteDetails) {
  const workSubmittedHistoryItem = (contract.history || []).find(
    ({ action }) => action === "ContractCompleted"
  );
  const date = workSubmittedHistoryItem?.updated_at || null;

  return date;
}

export function getContractFundDate(contract: ContractCompleteDetails) {
  const milestoneFundedItem = (contract.history || []).find(
    ({ action }) => action === "ContractMilestoneFunded"
  );
  const date = milestoneFundedItem?.updated_at || null;

  return date;
}

export function isContractIdValid(contractId: string) {
  return contractId.length > 8;
}
