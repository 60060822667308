import { useCallback, useEffect, useMemo } from "react";
import useSWR from "swr";
import dayjs from "dayjs";

import { useOnboardStripe } from "@/services/UserService";
import { useAuth } from "@/services/Authentication";
import { useSearchParams } from "@/services/Routing";
import Modal, { useModalState } from "@/components/misc/Modal";
import PayoutOnboardingUnderReview from "@/features/user/PayoutOnboardingUnderReview";
import { appFeatures, contractService } from "@/config/services";
import useCountdown from "@/hooks/useCountdown";
import { getFormattedDuration } from "@/utils/date";
import { Body, Heading } from "@/components/Typography";
import Button from "@/components/input/Button";

function useCanCreateOffer() {
  const { userData } = useAuth();
  const { searchParams } = useSearchParams();
  const { isOnboarded } = useOnboardStripe();
  const modalState = useModalState({ urlHash: "#account-under-review" });
  const waitTimeModalState = useModalState({
    urlHash: "#offer-creation-wait",
  });

  const onboardingSuccess = searchParams.get("onboarding") === "submitted";
  const canCreateOffer = !!userData?.can_create_offer;
  const onboardingUnderReview = !canCreateOffer && isOnboarded;
  const onboardingUnderReviewInitial =
    onboardingUnderReview && onboardingSuccess;

  const underReviewModalJsx = onboardingUnderReview && (
    <Modal showCloseButton state={modalState} width="560px">
      <PayoutOnboardingUnderReview
        showFirstTimeContent={onboardingUnderReviewInitial}
      />
    </Modal>
  );

  // -----------

  const { data: canCreateApiRes, isLoading } = useSWR(
    `/contract/can-create`,
    () => contractService.canCreateOffer(),
    {
      dedupingInterval: 1000 * 30, // 30 secs
    }
  );

  const nextAllowedCreationTime = useMemo(
    () =>
      canCreateApiRes?.nextAllowedCreationTime
        ? new Date(canCreateApiRes?.nextAllowedCreationTime)
        : null,
    [canCreateApiRes?.nextAllowedCreationTime]
  );

  const passedWaitDuration = useMemo(() => {
    if (!nextAllowedCreationTime) {
      return true;
    }

    const passedWaitDuration = dayjs(nextAllowedCreationTime) < dayjs();
    return passedWaitDuration;
  }, [nextAllowedCreationTime]);

  const { remainingTimeInMs, updateTargetTime, start } = useCountdown({
    targetTime: nextAllowedCreationTime,
    countUp: true,
    startOnMount: !passedWaitDuration,
  });

  const remainingTimeFormatted = useMemo(
    () => getFormattedDuration(remainingTimeInMs),
    [remainingTimeInMs]
  );

  const waitTimeJsx = !canCreateApiRes?.canCreate && (
    <>
      <Heading size="lg">Notice: Offer Creation Limit</Heading>

      <Body size="lg" className="mt-2">
        You can only create one offer within 24 hours of your first offer.
        Please wait until <b>{remainingTimeFormatted}</b> to create another.
        This measure is in place to ensure platform security and prevent misuse.
      </Body>
    </>
  );

  const waitTimeModalJsx = !canCreateApiRes?.canCreate && (
    <Modal showCloseButton state={waitTimeModalState} width="560px">
      {waitTimeJsx}

      <Button className="w-100 mt-4" onClick={waitTimeModalState.close}>
        Close
      </Button>
    </Modal>
  );

  //-----------------------
  const canCreateAfterWaitTime = appFeatures.isSupported(
    "CONTRACT.WAIT_TIME_FOR_SECOND_CONTRACT"
  )
    ? !!canCreateApiRes?.canCreate
    : true;
  const canCreate = !onboardingUnderReview && canCreateAfterWaitTime;

  const onCreateOffer = useCallback(
    (e: any) => {
      if (!canCreateAfterWaitTime) {
        if (e.preventDefault) e.preventDefault();
        if (e.stopPropagation) e.stopPropagation();
        waitTimeModalState.open();
        return;
      }

      if (onboardingUnderReview) {
        if (e.preventDefault) e.preventDefault();
        if (e.stopPropagation) e.stopPropagation();
        modalState.open();
      }
    },
    [
      modalState,
      onboardingUnderReview,
      canCreateAfterWaitTime,
      waitTimeModalState,
    ]
  );

  //-----------------------

  useEffect(() => {
    if (nextAllowedCreationTime) {
      updateTargetTime(nextAllowedCreationTime);
      start();
    }
  }, [nextAllowedCreationTime, updateTargetTime, start]);

  //-----------------------

  return {
    canCreate,
    onboardingUnderReview,
    onboardingUnderReviewInitial,
    jsx: (
      <>
        {underReviewModalJsx}
        {waitTimeModalJsx}
      </>
    ),
    onCreateOffer,
    isLoading,
    nextAllowedCreationTime: canCreateApiRes?.nextAllowedCreationTime,
    waitTimeJsx,
  };
}

export default useCanCreateOffer;
