"use client";

import styled from "@emotion/styled";

export const StyledContainer = styled.div`
  max-width: 100%;
  text-align: center;
  border-radius: 1rem;
  background: var(--clr-background-primary, #fff);
`;

export const StyledImage = styled.img`
  display: inline-block;
  font-size: 1.25rem;
  color: var(--clr-text-900, #0d0d12);
  font-weight: 600;
  margin: 0 auto;
`;

export const StyledHeading = styled.div`
  font-size: 1.25rem;
  color: var(--clr-text-900, #0d0d12);
  font-weight: 600;
`;

export const StyledContent = styled.div`
  font-size: 0.875rem;
  color: var(--clr-icon-text-200, #818898);
  font-weight: 500;
`;
