import React, { useMemo } from "react";

import {
  amIContractPayee,
  useShareOfferViaEmail,
} from "@/services/ContractsService";
import ContractShareButton from "@/features/contracts/ContractShareButton";
import Icon from "@/components/misc/Icon";
import Button from "@/components/input/Button";
import { analytics } from "@/config/services";
import EditOfferButton from "@/features/contracts/EditOfferButton";

import { IContractPreviewProps } from "../ContractPreview.types";

const CtaSection: React.FC<IContractPreviewProps> = ({
  contractDetails,
  className,
}) => {
  const { triggerShare, formModalJsx } = useShareOfferViaEmail({
    contractDetails,
    share_source: "from_dashboard",
  });

  const amIPayee = useMemo(
    () => amIContractPayee(contractDetails),
    [contractDetails]
  );

  const { id, title } = contractDetails;

  if (!amIPayee) {
    return null;
  }

  return (
    <>
      {formModalJsx}

      <div className={className}>
        <Button className="w-100" onClick={triggerShare}>
          Send to email <Icon isSrcRelative src="mail.svg" size="xs" />
        </Button>

        <ContractShareButton
          variant="secondary"
          className="w-100 mt-3"
          contractId={contractDetails?.id}
          onClick={({ shareType }) => {
            analytics.triggerShareOfferEvent("share_offer", {
              source: "from_dashboard",
              share_type: shareType,
              offer_id: id,
              offer_name: title,
            });
          }}
        >
          Or share via
          <Icon
            isSrcRelative
            src="share_2.svg"
            size="xxs"
            colorVariant="primary"
          />
        </ContractShareButton>

        {amIPayee && (
          <EditOfferButton
            className="w-100 mt-3"
            variant="secondary"
            contractId={id}
          >
            Edit offer
            <Icon
              isSrcRelative
              src="edit.svg"
              size="xxs"
              colorVariant="primary"
            />
          </EditOfferButton>
        )}
      </div>
    </>
  );
};

export default CtaSection;
