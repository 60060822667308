"use client";

import styled from "@emotion/styled";

import Button from "@/components/input/Button";
import Timeline from "@/components/misc/Timeline";
import DeleteMilestoneButton from "@/features/contracts/DeleteMilestoneButton";
import EditMilestoneButton from "@/features/contracts/EditMilestoneButton";
import { squareSizing } from "@/utils/css";

export const StyledButtomSmall = styled(Button)`
  padding: 0.15rem 0.75rem;
  min-width: 6rem;
`;

export const Milestone = styled.div`
  display: inline-block;
  padding: 0.125rem 0.25rem;
  font-weight: 500;
  background-color: #e9f0fe;
  border-radius: 0.25rem;
  font-size: 0.865rem;
`;

export const StyledTimeline = styled(Timeline)`
  --items-margin: 1.125rem;
`;

export const StyledDeleteMilestoneButton = styled(DeleteMilestoneButton)`
  ${squareSizing("1.5rem")};
`;

export const StyledEditMilestoneButton = styled(EditMilestoneButton)`
  ${squareSizing("1.5rem")};
`;
