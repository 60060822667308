import { useCallback, useState } from "react";
import styled from "@emotion/styled";

import { contractService } from "@/config/services";
import Modal, { useModalState } from "@/components/misc/Modal";
import { Body } from "@/components/Typography";
import Button from "@/components/input/Button";
import { useToast } from "@/components/misc/Toast";

import {
  ContractId,
  UpdateContractAttributes,
} from "../ContractsService.types";

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;

  & > * {
    flex: 1;
  }

  & > *:last-child {
    flex: 2;
  }
`;

function useUpdateOffer({
  contractId,
  onSuccess,
}: {
  contractId: ContractId;
  onSuccess?: () => void;
}) {
  const [isUpdating, setIsUpdating] = useState(false);
  const confirmationModalState = useModalState();
  const { createToast } = useToast();

  const [contractDetails, setContractDetails] =
    useState<UpdateContractAttributes | null>(null);

  // ----------------------------------

  const updateOffer = useCallback(() => {
    if (!contractDetails) {
      return;
    }

    setIsUpdating(true);

    const createSuccessToast = () => {
      const contractName = contractDetails?.title;

      createToast({
        title: "Offer updated!",
        description: contractName
          ? `Updated the offer: ${contractName}`
          : undefined,
        timeoutInMilliseconds: 3000,
      });
    };

    return contractService
      .updateOffer({ contractId, contractDetails })
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }

        confirmationModalState.close();
        createSuccessToast();
      })
      .catch(() => {
        createToast({
          variant: "error",
          title: "Something went wrong, Offer is not updated",
        });
      })
      .finally(() => {
        setIsUpdating(false);
      });
  }, [
    contractId,
    contractDetails,
    onSuccess,
    createToast,
    confirmationModalState,
  ]);

  const updateOfferWithConfirmation = useCallback(
    (contractDetails: UpdateContractAttributes) => {
      setContractDetails(contractDetails);
      confirmationModalState.open();
    },
    [confirmationModalState]
  );

  // ----------------------------------

  const submitButtonText = isUpdating ? "Updating Offer..." : "Update Offer";

  const confirmationJsx = (
    <Modal state={confirmationModalState} width="306px">
      <Body size="lg">Are you sure want to update this offer?</Body>
      <StyledButtonsContainer className="mt-4">
        <Button
          variant="secondary"
          colorVariant="gray"
          onClick={confirmationModalState.close}
        >
          Cancel
        </Button>
        <Button disabled={isUpdating} onClick={updateOffer}>
          {submitButtonText}
        </Button>
      </StyledButtonsContainer>
    </Modal>
  );

  return {
    isUpdating,
    updateOffer: updateOfferWithConfirmation,
    jsx: confirmationJsx,
  };
}

export default useUpdateOffer;
