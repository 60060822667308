import React, { useEffect } from "react";

import TransactionNameInput from "../inputs/TransactionNameInput";
import TransactionPriceInput from "../inputs/TransactionPriceInput";
import ClientNameInput from "../inputs/ClientNameInput";
import TransactionDateInput from "../inputs/TransactionDateInput";
import TransactionFilesInput from "../inputs/TransactionFilesInput";
import TransactionDescriptionField from "../inputs/TransactionDescriptionField";
import { useContractForm } from "../../OfferUpdateForm.config";

const OneTimeContractDetailsStep: React.FC = () => {
  const { values, setFieldValue } = useContractForm();

  useEffect(() => {
    if (!values.milestones?.length) {
      setFieldValue("milestones", [{}]);
    }
  }, [values.milestones?.length, setFieldValue]);

  return (
    <>
      <TransactionNameInput />

      <ClientNameInput className="mt-3" />

      <TransactionPriceInput className="mt-2" />

      <TransactionDateInput className="mt-2" />

      <TransactionDescriptionField className="mt-4" />

      <TransactionFilesInput className="mt-2" />
    </>
  );
};

export default OneTimeContractDetailsStep;
