import { useMemo } from "react";
import useSWR from "swr";

import { contractService } from "@/config/services";
import { useAppSelector, selectContracts } from "@/services/Store";
import { generateTimelineItems } from "@/features/contracts/ContractTimeline";

import {
  amIContractParticipant,
  amIContractPayee,
  amIContractPayer,
  contractModelToCompleteDetails,
  getLoggedInUserContractUserType,
  isContractAJob,
  isContractAnOffer,
  isContractCompleted,
} from "../utils/contract";
import {
  ContractListFilters,
  CreatedContractDetails,
} from "../ContractsService.types";
import {
  getNextActiveMilestone,
  isMilestoneFundReleasable,
  isMilestoneFundRequestable,
} from "../utils";

function useContractList(
  config: {
    onlyJobs?: boolean;
    onlyOffers?: boolean;
    onlyCompletedJobs?: boolean;
    onlyActiveJobs?: boolean;
    onlyWithActions?: boolean;
    filters?: ContractListFilters;
  } = {}
) {
  const contracts = useAppSelector(selectContracts);

  const {
    onlyJobs = false,
    onlyOffers = false,
    onlyCompletedJobs = false,
    onlyActiveJobs = false,
    onlyWithActions = false,
    filters = {},
  } = config;
  const { sort = "updated_at:asc" } = filters;

  const { error, isLoading, mutate } = useSWR(
    () => contractService.getContractListEndpoint(filters),
    () => contractService.fetchContracts(filters),
    { errorRetryInterval: 10000, errorRetryCount: 5, dedupingInterval: 20000 }
  );

  const filtered = useMemo(() => {
    let filtered = ((contracts as CreatedContractDetails[]) || [])
      .map(contractModelToCompleteDetails)
      .filter((c) => !c.is_disabled);

    if (onlyOffers) {
      filtered = filtered.filter(isContractAnOffer);
    }

    if (onlyJobs) {
      filtered = filtered.filter(isContractAJob);
    }

    if (onlyCompletedJobs) {
      filtered = filtered
        .filter(isContractAJob)
        .filter((contract) => isContractCompleted(contract));
    }

    if (onlyActiveJobs) {
      filtered = filtered
        .filter(isContractAJob)
        .filter((c) => !isContractCompleted(c));
    }

    if (onlyWithActions) {
      filtered = filtered
        .filter((contractDetails) => {
          const { milestone: nextActiveMilestone } =
            getNextActiveMilestone(contractDetails);
          const amIPayer = amIContractPayer(contractDetails);
          const amIPayee = amIContractPayee(contractDetails);
          const isCompleted = isContractCompleted(contractDetails);

          const showNewOfferButton = isCompleted && amIPayee;
          const showHireAgainButton = isCompleted && amIPayer;

          if (!nextActiveMilestone) {
            return {
              canFund: false,
              canSubmit: false,
              canReview: false,
              showRequestPaymentButton: false,
              showReleasePaymentButton: false,
              showChatButton: isCompleted,
              showNewOfferButton,
              showHireAgainButton,
            };
          }

          const canReleasePayment =
            isMilestoneFundReleasable(nextActiveMilestone) && amIPayer;

          const canRequestPayment =
            isMilestoneFundRequestable(nextActiveMilestone) && amIPayee;

          if (!contractDetails || !amIContractParticipant(contractDetails)) {
            return { items: [], currentIndex: 0 };
          }

          const userType = getLoggedInUserContractUserType(contractDetails);

          const { items: timelineItems, currentIndex } = userType
            ? generateTimelineItems({
                contract: contractDetails,
                userType,
              })
            : { items: [], currentIndex: 0 };
          const ctaFromTimeline = timelineItems[currentIndex]?.ctaAction;

          const IGNORED_ACTIONS = ["submit-work"];

          if (ctaFromTimeline && IGNORED_ACTIONS.includes(ctaFromTimeline)) {
            return false;
          }

          const hasAction =
            ctaFromTimeline || canRequestPayment || canReleasePayment;

          return hasAction;
        })
        .filter((c) => !isContractCompleted(c));
    }

    return filtered;
  }, [
    onlyJobs,
    onlyOffers,
    onlyCompletedJobs,
    onlyActiveJobs,
    contracts,
    onlyWithActions,
  ]);

  const contractListChronologicallySorted = useMemo(
    () =>
      sort === "updated_at:asc"
        ? [...filtered].sort(
            (a, b) =>
              new Date(b.updated_at).getTime() -
              new Date(a.updated_at).getTime()
          )
        : sort === "created_at:asc"
        ? [...filtered].sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
        : sort === "title:asc"
        ? [...filtered].sort((a, b) => a.title.localeCompare(b.title))
        : filtered,
    [sort, filtered]
  );

  return {
    data: contractListChronologicallySorted,
    error,
    isLoading,
    isUpdating: !!contracts && isLoading,
    hasNoData: !contracts.length && !isLoading,
    reload: mutate,
  };
}

export default useContractList;
