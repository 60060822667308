import React, { useCallback } from "react";

import Button from "@/components/input/Button";

import {
  StyledContainer,
  StyledImage,
  StyledHeading,
  StyledContent,
} from "./MilestoneDeleteConfirmation.styles";
import { useContractForm } from "../../../ContractForm.context";

const MilestoneDeleteConfirmation: React.FC<{
  milestoneIndex: number;
  onCancel: () => void;
  onDelete: () => void;
}> = ({ milestoneIndex, onCancel, onDelete }) => {
  const { values, setFieldValue } = useContractForm();
  const { milestones } = values;

  const deleteMilestone = useCallback(() => {
    const newMilestones = [...milestones].toSpliced(milestoneIndex, 1);
    setFieldValue("milestones", newMilestones);
    onDelete();
  }, [milestones, setFieldValue, milestoneIndex, onDelete]);

  return (
    <StyledContainer>
      <StyledImage src="/assets/images/pages/contracts/cancel.svg" />

      <StyledHeading className="mt-4">Delete the milestone?</StyledHeading>
      <StyledContent className="mt-2">
        Are you sure you want to permanently remove this milestone?
      </StyledContent>

      <Button
        colorVariant="danger"
        className="w-100 mt-4"
        onClick={deleteMilestone}
      >
        Delete
      </Button>
      <Button
        variant="ghost"
        colorVariant="black"
        className="w-100"
        onClick={onCancel}
      >
        Cancel
      </Button>
    </StyledContainer>
  );
};

export default MilestoneDeleteConfirmation;
