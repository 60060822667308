export enum ContractCurrency {
  USD = "USD",
}

export enum ContractTransactionType {
  FullPayment = "full_payment",
}

export enum ContractType {
  Hourly = "hourly",
  OneTime = "full_payment",
  Milestone = "milestone",
}

export enum ContractCreatorType {
  Payer = "client",
  Payee = "contractor",
}

export enum ContractStatus {
  Dispute = "dispute",
  Archive = "archived",
  Paused = "paused",
  Approved = "approved",
  Accepted = "accepted",
  Reject = "reject",
  Funded = "funded",
  Pending = "pending",
  Review = "review",
  Published = "published",
  //-------------
  PendingFunding = "pending-funding",
  Active = "active",
  Completed = "completed",
}

// ------------| BE Enum |------------
// export enum ContractMilestoneStatus {
//   PUBLISHED = "published",
//   PENDING = "pending",
//   READYFORWORK = "ready-for-work"
//   REVIEW = "review",
//   DISPUTE = "dispute",
//   COMPLETED = "completed",
//   ARCHIVED = "archived",
//   FUNDED = "funded",
//   REJECTED = "rejected",
// };

export enum ContractMilestoneStatus {
  Published = "published",
  Accepted = "accepted",
  ReadyForWork = "ready-for-work",
  Review = "review",
  Completed = "completed",
  // Dispute = "dispute",
  // Pending = "pending",
  //-----------------
  Funded = "funded",
  FundRequested = "fund-requested",
  FundReleased = "fund-released",
}

export enum ContractMilestoneStatusNew {
  Published = "published",
  Accepted = "accepted",
  Funded = "funded",
  FundingRequested = "funding-requested",
  FundingReleased = "funding-released",
}

export type ContractMilestone = {
  name: string;
  description: string;
  value: any;
  start_date: string | null;
  end_date: string | null;
  currency: ContractCurrency;
};

export type ContractCreatePayload = {
  description: string;
  title: string;
  start_date: string | null;
  end_date: string | null;
  transaction_type: ContractTransactionType;
  contract_type: ContractType;
  milestones: ContractMilestone[];
  created_as: ContractCreatorType;
  attachments: number[];
  client_email_number: string;
  client_name: string;
};

export enum ContractCreationFlow {
  AI = "AI",
  Hourly = "hourly",
  Fixed = "fixed",
  OneTime = "full_payment",
  Milestone = "milestone",
}

export type ContractParticipant = {
  id: number;
  role: ContractCreatorType;

  user_id: number;
  contract_id: string;
  contract_milestone_id: number;
  updated_at: string;
  created_at: string;

  user: {
    id: number;
    email: string;
    given_name: string;
    family_name: null | string;
    picture: null | string;

    owner_id: null | string;
    stripe_customer_id: null | string;
    owner_profile: null;
    stripe_connect_data: null;
    updated_at: string;
    created_at: string;
    details: null | string;
    paypipe_id: null | string;
    last_updated_paypipe_id: null | string;
    profile_published: boolean;
  };
};

export type CreatedContractDetails = {
  id: string;
  owner_id: number;
  channel_id: string;
  description: string;
  total_value: null;
  title: string;
  rejected_title: null;
  rejected_reason: null;
  is_active: boolean;
  status: ContractStatus;
  parent_id: null;
  completed_at: null;
  completed_by: null;
  start_date: null;
  end_date: null;
  transaction_type: null | ContractTransactionType;
  contract_type: ContractType;
  accepted_by: null;
  rejected_by: null;
  accepted_at: null;
  rejected_at: null;
  updated_at: string;
  created_at: string;
  participants: ContractParticipant[];
};

export type MilestoneEscrowStatus = "released" | "pending";

export type MilestoneEscrowDetails = {
  contract_id: string;
  contract_milestone_id: number;
  created_at: string;
  destionation_wallet_id: number;
  id: number;
  source_type: string;
  source_wallet_id: number;
  status: MilestoneEscrowStatus;
  stripe_payment_intent_id: string;
  updated_at: string;
  value: string;
};

export type MilestoneId = number;
export type ContractId = string;

export type MilestoneCompleteDetails = {
  id: MilestoneId;
  name: string;
  description: string;
  currency: ContractCurrency;
  revision_status: null;
  is_active: true;
  version: number;
  parent_milestone_id: null;
  owner_id: number;
  contract_id: string;
  value: any;
  status: ContractMilestoneStatus;
  // status: string;
  start_date: string | null;
  end_date: string | null;
  rejected_by: null;
  rejected_at: null;
  approved_by: null;
  approved_at: null;
  release_at: null;
  updated_at: string;
  created_at: string;
  participants: ContractParticipant[];
  escrow: MilestoneEscrowDetails;
  has_work: boolean;
  is_draft: boolean;
  is_deleted: boolean;
};

export type ContractCompleteDetails = {
  id: string;
  owner_id: number;
  channel_id: string;
  description: string;
  total_value: null;
  title: string;
  rejected_title: null;
  rejected_reason: null;
  is_active: boolean;
  status: ContractStatus;
  parent_id: null;
  completed_at: null;
  completed_by: null;
  start_date: null;
  end_date: null;
  transaction_type: null | ContractTransactionType;
  contract_type: ContractType;
  accepted_by: null;
  rejected_by: null;
  accepted_at: null;
  rejected_at: null;
  updated_at: string;
  created_at: string;
  rejectedBy: null;
  acceptedBy: null;
  history: {
    id: number;
    action: string;
    is_active: true;
    data: string;
    contract_milestone_id: null | number;
    contract_milestone_work_id: null;
    contract_id: string;
    updated_at: string;
    created_at: string;
  }[];
  files: {
    id: number;
    url: string;
    owner_id: number;
    is_active: true;
    milestone_id: null;
    milestone_work_id: null;
    contract_id: string;
    updated_at: string;
    created_at: string;
  }[];
  milestones: MilestoneCompleteDetails[];
  participants: ContractParticipant[];
  is_disabled?: boolean;
};

export type MilestoneWork = {
  id: number;
  description: string;
  is_active: boolean;
  contract_milestone_id: number;
  contract_id: string;
  updated_at: string;
  created_at: string;
  files: any[];
};

export type PaymentResponse = {
  id: string;
  object: string;
  after_expiration: null;
  allow_promotion_codes: null;
  amount_subtotal: number;
  amount_total: number;
  automatic_tax: {
    enabled: boolean;
    liability: null;
    status: null;
  };
  billing_address_collection: null;
  cancel_url: string;
  client_reference_id: null;
  client_secret: null;
  consent: null;
  consent_collection: null;
  created: number;
  currency: "usd";
  currency_conversion: null;
  custom_fields: [];
  custom_text: {
    after_submit: null;
    shipping_address: null;
    submit: null;
    terms_of_service_acceptance: null;
  };
  customer: null;
  customer_creation: string;
  customer_details: null;
  customer_email: null;
  expires_at: number;
  invoice: null;
  invoice_creation: {
    enabled: false;
    invoice_data: {
      account_tax_ids: null;
      custom_fields: null;
      description: null;
      footer: null;
      issuer: null;
      metadata: {};
      rendering_options: null;
    };
  };
  livemode: false;
  locale: null;
  metadata: {
    contract_id: string;
    milestone_id: string;
    mode: string;
  };
  mode: "payment";
  payment_intent: null;
  payment_link: null;
  payment_method_collection: string;
  payment_method_configuration_details: {
    id: string;
    parent: null;
  };
  payment_method_options: {
    affirm: {};
    card: {
      request_three_d_secure: string;
    };
    us_bank_account: {
      financial_connections: {
        permissions: ["payment_method"];
        prefetch: [];
      };
      verification_method: string;
    };
    wechat_pay: {
      app_id: null;
      client: string;
    };
  };
  payment_method_types: [
    "card",
    "alipay",
    "klarna",
    "us_bank_account",
    "wechat_pay",
    "affirm",
    "cashapp",
    "amazon_pay"
  ];
  payment_status: string;
  phone_number_collection: {
    enabled: boolean;
  };
  recovered_from: null;
  saved_payment_method_options: null;
  setup_intent: null;
  shipping_address_collection: null;
  shipping_cost: null;
  shipping_details: null;
  shipping_options: [];
  status: "open";
  submit_type: null;
  subscription: null;
  success_url: string;
  total_details: {
    amount_discount: number;
    amount_shipping: number;
    amount_tax: number;
  };
  ui_mode: string;
  url: string;
};

export type ContractMetaData = {
  amIPayer: boolean;
  amIPayee: boolean;
  isApproved: boolean;
  isCompleted: boolean;
  nextSubmittableMilestone: MilestoneCompleteDetails | null;
  nextReviewableMilestone: MilestoneCompleteDetails | null;
  nextFundableMilestone: MilestoneCompleteDetails | null;
  //----------------
  canManage: boolean;
  canApprove: boolean;
  canPreview: boolean;
};

// ------------------------

export type ContractSortFilters =
  | "updated_at:asc"
  | "created_at:asc"
  | "title:asc";

export type ContractListFilters = {
  limit?: number;
  offset?: number;
  contract_type?: ContractType;
  sort?: ContractSortFilters;
  status?: ContractStatus;
};

//---------------------------

export type ShareOfferViaEmailParams = {
  email: string;
  clientName: string;
  offerName: string;
  offerId: string;
};

//---------------------------

export interface IContractsServiceContext {
  showOfferShareEmailModal: (data: { recipientEmail: string }) => void;
}

//---------------------------

export type PaymentStatus = {
  payment: {
    succeeded: boolean;
    isBankAccountPayment: boolean;
    paymentCompleted: boolean;
    value: number;
    currency: string;
  };
};

//---------------------------

export type UpdateContractMilestoneAttributes = Partial<
  Pick<
    MilestoneCompleteDetails,
    "name" | "description" | "value" | "currency" | "start_date" | "end_date"
  > &
    Pick<MilestoneCompleteDetails, "id">
>;

export type UpdateContractAttributes = Partial<
  Pick<
    ContractCompleteDetails,
    "title" | "description" | "transaction_type" | "contract_type"
  > & {
    milestones?: Partial<UpdateContractMilestoneAttributes>[];
  }
>;

export type AddContractMilestoneAttributes = Partial<
  Pick<
    MilestoneCompleteDetails,
    "name" | "description" | "value" | "currency" | "start_date" | "end_date"
  >
>;
