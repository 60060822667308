import * as yup from "yup";

import { useForm } from "@/components/input/Form";

import { MilestoneFormValues } from "./MilestoneForm.types";
import {
  ContractCurrency,
  MAX_MILESTONE_VALUE,
  MIN_MILESTONE_VALUE,
} from "@/services/ContractsService";

export const useMilestoneForm = useForm<MilestoneFormValues>;

export const DATES_OPTIONAL = true;

export const ERRORS = {
  MIN_PRICE_ERROR: "MIN_PRICE_ERROR",
  MAX_PRICE_ERROR: "MAX_PRICE_ERROR",
};

export const INITIAL_VALUES = {
  name: "",
  description: "",
  start_date: null,
  end_date: null,
  currency: ContractCurrency.USD,
  value: 0,
} as MilestoneFormValues;

export const VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  value: yup
    .number()
    .required()
    .min(MIN_MILESTONE_VALUE, ERRORS.MIN_PRICE_ERROR)
    .max(MAX_MILESTONE_VALUE, ERRORS.MAX_PRICE_ERROR),

  ...(DATES_OPTIONAL
    ? {}
    : {
        start_date: yup.string().required(),
        end_date: yup.string().required(),
      }),
});
