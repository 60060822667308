import React, { useMemo } from "react";

import {
  CONTRACT_TYPE_CONFIG,
  getContractOtherParticipant,
  getContractPageUrl,
  getContractTotalValue,
  getNextActiveMilestone,
  getOfferPageUrl,
  getOfferShareContent,
  isContractAnOffer,
} from "@/services/ContractsService";
import { getDateFromNowText } from "@/utils/date";
import ContractStatus from "@/features/contracts/ContractStatus";
import DotSeparated from "@/components/misc/DotSeparated";
import Icon from "@/components/misc/Icon";
import { Label } from "@/components/Typography";
import { getUserFullName } from "@/services/UserService";
import ContractNextActionButton from "@/features/contracts/ContractNextActionButton";
import { appFeatures } from "@/config/services";

import {
  StyledActionButtonsContainer,
  StyledButton,
  StyledCard,
  StyledDeleteOfferButton,
  StyledEditOfferButton,
  StyledLink,
  StyledPrimaryText,
  StyledRow,
  StyledSecodaryText,
  StyledShareButton,
  StyledTopContainer,
  StyledValue,
} from "./ContractCard.styles";
import { IContractCardProps } from "./ContractCard.types";

const ContractCard: React.FC<IContractCardProps> = ({
  contract,
  className,
  openInNewTab,
  active = false,
  showCta = false,
}) => {
  const { id, title, status, contract_type } = contract;

  const otherUserDisplayName = useMemo(() => {
    const otherUser = getContractOtherParticipant(contract);
    let displayName = otherUser?.user?.email;
    if (!displayName && !!otherUser?.user) {
      displayName = getUserFullName(otherUser?.user);
    }

    if (!displayName) {
      displayName = "";
    }

    return displayName;
  }, [contract]);

  const totalValue = useMemo(
    () =>
      getNextActiveMilestone(contract)?.milestone?.value ||
      getContractTotalValue(contract),
    [contract]
  );

  const contractTypeLabel = useMemo(
    () => CONTRACT_TYPE_CONFIG[contract_type].info.title,
    [contract_type]
  );

  const isOffer = useMemo(() => isContractAnOffer(contract), [contract]);

  const link = useMemo(
    () => (isOffer ? getOfferPageUrl(id) : getContractPageUrl(id)),
    [isOffer, id]
  );

  const showShareButton = isOffer;

  const showStatusOnTop = !showShareButton && showCta;

  const {
    status: nextActiveMilestoneStatus,
    end_date: nextActiveMilestoneDueDate,
  } = useMemo(() => {
    const { milestone: nextActiveMilestone } = getNextActiveMilestone(contract);

    if (nextActiveMilestone) {
      return nextActiveMilestone;
    }

    return {} as any;
  }, [contract]);

  const displayDateFomatted = useMemo(() => {
    let dueData = "";

    if (nextActiveMilestoneDueDate) {
      dueData = getDateFromNowText(nextActiveMilestoneDueDate);

      return `due ${dueData}`;
    }
  }, [nextActiveMilestoneDueDate]);

  return (
    <StyledCard $active={active} className={className}>
      <StyledLink to={link} target={openInNewTab ? "_blank" : "_self"} />

      <StyledRow>
        <StyledTopContainer>
          <StyledPrimaryText size="lg">{title}</StyledPrimaryText>
          {showStatusOnTop && (
            <ContractStatus
              className="status"
              status={status}
              milestoneStatus={nextActiveMilestoneStatus}
              contractDetails={contract}
            />
          )}
        </StyledTopContainer>

        {false && (
          <StyledButton className="util-button" variant="secondary">
            <Icon
              isSrcRelative
              src="menu_bold.svg"
              customSize="0.75rem"
              colorVariant="black"
            />
          </StyledButton>
        )}

        {isOffer && (
          <StyledActionButtonsContainer>
            {showShareButton && (
              <StyledShareButton
                className="util-button"
                content={getOfferShareContent({ contractId: id })}
                buttonProps={{
                  variant: "secondary",
                  children: (
                    <Icon
                      isSrcRelative
                      src="share_3.svg"
                      customSize="0.75rem"
                      colorVariant="black"
                    />
                  ),
                }}
              />
            )}

            <StyledEditOfferButton
              className="util-button"
              variant="secondary"
              contractId={id}
            >
              <Icon isSrcRelative src="edit.svg" customSize="0.75rem" />
            </StyledEditOfferButton>

            {appFeatures.isSupported("CONTRACT.DELETE_OFFER") && (
              <StyledDeleteOfferButton
                className="util-button"
                variant="secondary"
                contractId={id}
              >
                <Icon isSrcRelative src="delete.svg" customSize="0.75rem" />
              </StyledDeleteOfferButton>
            )}
          </StyledActionButtonsContainer>
        )}
      </StyledRow>

      <StyledRow>
        <StyledSecodaryText size="md">
          {otherUserDisplayName}
        </StyledSecodaryText>
        {!!totalValue && <StyledValue>${totalValue}</StyledValue>}
      </StyledRow>

      <StyledRow className="mt-2 flex-wrap">
        {showCta ? (
          <ContractNextActionButton contractId={contract.id} />
        ) : (
          <ContractStatus
            status={status}
            milestoneStatus={nextActiveMilestoneStatus}
            contractDetails={contract}
          />
        )}

        <DotSeparated>
          <Label size="sm">{contractTypeLabel}</Label>

          {!!displayDateFomatted && (
            <Label size="sm">{displayDateFomatted}</Label>
          )}
        </DotSeparated>
      </StyledRow>
    </StyledCard>
  );
};

export default ContractCard;
