import * as yup from "yup";

import {
  ContractTransactionType,
  ContractType,
  MIN_MILESTONE_VALUE,
  MAX_MILESTONE_VALUE,
} from "@/services/ContractsService";
import { useForm } from "@/components/input/Form";

import { ClientInputType, OfferFormValues } from "./OfferUpdateForm.types";

export const DATES_OPTIONAL = true;
export const CLIENT_NAME_OPTIONAL = true;

export const STEP_URL_PARAM_NAME = "step";

export const CLIENT_NAME_CHAR_LIMIT = 100;

export const ERRORS = {
  MIN_PRICE_ERROR: "MIN_PRICE_ERROR",
  MAX_PRICE_ERROR: "MAX_PRICE_ERROR",
};

export const useContractForm = useForm<OfferFormValues>;

export const VALIDATION_SCHEMA = yup.object().shape({
  title: yup.string().required(),
  description: yup.string().required(),
  transaction_type: yup
    .string()
    .required()
    .oneOf(Object.values(ContractTransactionType)),
  contract_type: yup.string().required().oneOf(Object.values(ContractType)),
  // client_email_number: yup.string().required(),
  client_name: CLIENT_NAME_OPTIONAL ? yup.string() : yup.string().required(),

  milestones: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required(),
        description: yup.string().required(),
        // currency: yup.string().required(),
        value: yup
          .number()
          .required()
          .min(MIN_MILESTONE_VALUE, ERRORS.MIN_PRICE_ERROR)
          .max(MAX_MILESTONE_VALUE, ERRORS.MAX_PRICE_ERROR),

        ...(DATES_OPTIONAL
          ? {}
          : {
              start_date: yup.string().required(),
              end_date: yup.string().required(),
            }),
      })
    )
    .min(1),
});

export const INITIAL_VALUES: OfferFormValues = {
  description: "",
  title: "",
  transaction_type: ContractTransactionType.FullPayment,
  contract_type: "" as ContractType.OneTime,
  files: [],
};

export const CLIENT_INPUT_OPTIONS = [
  {
    value: ClientInputType.PaypipeId,
    children: "Paypipe ID",
  },
  {
    value: ClientInputType.Email,
    children: "Email",
  },
];
