import React from "react";

import Button, { IButtonProps } from "@/components/input/Button";
import {
  ContractId,
  MilestoneId,
  useLaunchMilestone,
} from "@/services/ContractsService";

const LaunchMilestoneButton: React.FC<
  IButtonProps & { contractId: ContractId; milestoneId: MilestoneId }
> = ({ contractId, milestoneId, children, ...buttonProps }) => {
  const { isLaunching, launchMilestone, jsx } = useLaunchMilestone({
    contractId,
    milestoneId,
  });

  const buttonText = isLaunching
    ? "Publishing Milestone..."
    : "Publish Milestone";

  return (
    <>
      {jsx}
      <Button
        variant="secondary"
        {...buttonProps}
        disabled={isLaunching}
        onClick={launchMilestone}
      >
        {children || buttonText}
      </Button>
    </>
  );
};

export default LaunchMilestoneButton;
