import React, { useEffect } from "react";

import SplashScreen from "@/features/pages/app/SplashScreen";
import { useAuth } from "@/services/Authentication";
import Redirect from "@/components/navigation/Redirect";
import { SITE_PATHS } from "@/config/routing";

const AuthRedirectPage: React.FC = () => {
  const { isAuthenticated, ...rest } = useAuth();

  useEffect(() => {
    console.log("AUTH:DEBUG", { isAuthenticated, ...rest });
  }, [isAuthenticated, rest]);

  if (isAuthenticated) {
    return <Redirect path={SITE_PATHS.HOME_PAGE} />;
  }

  return <SplashScreen />;
};

export default AuthRedirectPage;
