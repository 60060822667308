import { IButtonProps } from "@/components/input/Button";
import { FileObject } from "@/features/input/FilesInput";
import {
  ContractCreationFlow,
  UpdateContractAttributes,
} from "@/services/ContractsService";

export type OfferFormValues = UpdateContractAttributes & {
  files: FileObject[];
  flow?: ContractCreationFlow;
  client_paypipe_id?: string;
  client_email_number?: string;
  client_name?: string;
};

export interface IContractFormProps {
  initialValues?: OfferFormValues;
  className?: string;
  onChange?: (values: OfferFormValues) => void;
  onSubmit?: (values: OfferFormValues) => void;
  submitButtonProps?: IButtonProps;
}

export enum ClientInputType {
  PaypipeId = "PaypipeId",
  Email = "Email",
}
