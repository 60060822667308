import { useCallback, useState } from "react";
import styled from "@emotion/styled";

import { contractService } from "@/config/services";
import Modal, { useModalState } from "@/components/misc/Modal";
import { Body } from "@/components/Typography";
import Button from "@/components/input/Button";

import { ContractId } from "../ContractsService.types";

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;

  & > * {
    flex: 1;
  }

  & > *:last-child {
    flex: 2;
  }
`;

function useCloseJob({ contractId }: { contractId: ContractId }) {
  const [isClosing, setIsDeleting] = useState(false);
  const setIsClosing = useModalState();

  // ----------------------------------

  const deleteMilestone = useCallback(() => {
    setIsDeleting(true);

    return contractService
      .closeJob({ contractId })
      .then(() => {
        setIsClosing.close();
      })
      .finally(() => {
        setIsDeleting(false);
      });
  }, [contractId, setIsClosing]);

  // ----------------------------------

  const submitButtonText = isClosing ? "Closing Job..." : "Close Job";

  const confirmationJsx = (
    <Modal state={setIsClosing} width="300px">
      <Body size="lg">Are you sure want to close this job?</Body>
      <StyledButtonsContainer className="mt-4">
        <Button
          variant="secondary"
          colorVariant="gray"
          onClick={setIsClosing.close}
        >
          Cancel
        </Button>
        <Button
          colorVariant="danger"
          disabled={isClosing}
          onClick={deleteMilestone}
        >
          {submitButtonText}
        </Button>
      </StyledButtonsContainer>
    </Modal>
  );

  return {
    isClosing,
    closeJob: setIsClosing.open,
    jsx: confirmationJsx,
  };
}

export default useCloseJob;
