import React from "react";

import Button, { IButtonProps } from "@/components/input/Button";
import { ContractId, useCloseJob } from "@/services/ContractsService";

const CloseJobButton: React.FC<IButtonProps & { contractId: ContractId }> = ({
  contractId,
  children,
  ...buttonProps
}) => {
  const { closeJob, isClosing, jsx } = useCloseJob({
    contractId,
  });

  return (
    <>
      {jsx}
      <Button
        colorVariant="danger"
        variant="secondary"
        {...buttonProps}
        disabled={isClosing}
        onClick={closeJob}
      >
        {children || "Close Job"}
      </Button>
    </>
  );
};

export default CloseJobButton;
