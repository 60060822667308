import { useCallback, useState } from "react";
import styled from "@emotion/styled";

import { contractService } from "@/config/services";
import Modal, { useModalState } from "@/components/misc/Modal";
import { Body } from "@/components/Typography";
import Button from "@/components/input/Button";

import { ContractId, MilestoneId } from "../ContractsService.types";

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;

  & > * {
    flex: 1;
  }

  & > *:last-child {
    flex: 2;
  }
`;

function useLaunchMilestone({
  milestoneId,
  contractId,
}: {
  milestoneId: MilestoneId;
  contractId: ContractId;
}) {
  const [isLaunching, setIsLaunching] = useState(false);
  const confirmationModalState = useModalState();

  // ----------------------------------

  const launchMilestone = useCallback(() => {
    setIsLaunching(true);

    return contractService
      .launchMilestone({ milestoneId, contractId })
      .then(() => {
        confirmationModalState.close();
      })
      .finally(() => {
        setIsLaunching(false);
      });
  }, [milestoneId, contractId, confirmationModalState]);

  // ----------------------------------

  const submitButtonText = isLaunching
    ? "Publishing Milestone..."
    : "Publish Milestone";

  const confirmationJsx = (
    <Modal state={confirmationModalState} width="360px">
      <Body size="lg">
        Milestone cannot be edited once published. Are you sure want to launch
        this milestone?
      </Body>
      <StyledButtonsContainer className="mt-4">
        <Button
          variant="secondary"
          colorVariant="gray"
          onClick={confirmationModalState.close}
        >
          Cancel
        </Button>
        <Button
          disabled={isLaunching}
          onClick={launchMilestone}
        >
          {submitButtonText}
        </Button>
      </StyledButtonsContainer>
    </Modal>
  );

  return {
    isLaunching,
    launchMilestone: confirmationModalState.open,
    jsx: confirmationJsx,
  };
}

export default useLaunchMilestone;
