import { useCallback, useState } from "react";
import styled from "@emotion/styled";

import { contractService } from "@/config/services";
import Modal, { useModalState } from "@/components/misc/Modal";
import { Body } from "@/components/Typography";
import Button from "@/components/input/Button";

import { ContractId, MilestoneId } from "../ContractsService.types";

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;

  & > * {
    flex: 1;
  }

  & > *:last-child {
    flex: 2;
  }
`;

function useDeleteMilestone({
  milestoneId,
  contractId,
}: {
  milestoneId: MilestoneId;
  contractId: ContractId;
}) {
  const [isDeleting, setIsDeleting] = useState(false);
  const confirmationModalState = useModalState();

  // ----------------------------------

  const deleteMilestone = useCallback(() => {
    setIsDeleting(true);

    return contractService
      .deleteMilestone({ milestoneId, contractId })
      .then(() => {
        confirmationModalState.close();
      })
      .finally(() => {
        setIsDeleting(false);
      });
  }, [milestoneId, contractId, confirmationModalState]);

  // ----------------------------------

  const submitButtonText = isDeleting
    ? "Deleting Milestone..."
    : "Delete Milestone";

  const confirmationJsx = (
    <Modal state={confirmationModalState} width="300px">
      <Body size="lg">Are you sure want to delete this milestone?</Body>
      <StyledButtonsContainer className="mt-4">
        <Button
          variant="secondary"
          colorVariant="gray"
          onClick={confirmationModalState.close}
        >
          Cancel
        </Button>
        <Button
          colorVariant="danger"
          disabled={isDeleting}
          onClick={deleteMilestone}
        >
          {submitButtonText}
        </Button>
      </StyledButtonsContainer>
    </Modal>
  );

  return {
    isDeleting,
    deleteMilestone: confirmationModalState.open,
    jsx: confirmationJsx,
  };
}

export default useDeleteMilestone;
