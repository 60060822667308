import React from "react";

import TextField from "@/components/input/TextField";

import TransactionNameInput from "../inputs/TransactionNameInput";
import TransactionPriceInput from "../inputs/TransactionPriceInput";
import ClientNameInput from "../inputs/ClientNameInput";

const HourlyContractDetailsStep: React.FC = () => {
  return (
    <>
      <TransactionNameInput />

      <ClientNameInput className="mt-2" />

      <TextField className="mt-2" placeholder="Set weekly limit" />
      
      <TextField
        className="mt-2"
        placeholder="Set number of hours"
        type="number"
      />
      <TransactionPriceInput className="mt-2" />
    </>
  );
};

export default HourlyContractDetailsStep;
