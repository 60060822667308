import React, { useCallback, useMemo } from "react";
import lodashGet from "lodash/get";

import DateField from "@/components/input/DateField";
import { SingleDateValue } from "@/components/input/Calendar";

import { useContractForm } from "../../OfferUpdateForm.config";
import { DATES_OPTIONAL } from "../../OfferUpdateForm.config";

const TransactionDateInput: React.FC<{ className?: string }> = ({
  className,
}) => {
  const { values, setFieldValue, touched, setFieldTouched } = useContractForm();

  const { milestones } = values;
  const endDate = lodashGet(milestones, "[0].end_date", null);
  const endDateTouched = lodashGet(touched, "milestones[0].end_date", false);
  const endDateHasError = endDateTouched && !endDate;
  const hasError = endDateHasError;

  const value = useMemo(() => (endDate ? new Date(endDate) : null), [endDate]);

  //--------------------------

  const handleChange = useCallback(
    (value?: SingleDateValue) => {
      const endDateValue = value ? value.toISOString() : null;
      setFieldValue("end_date", endDateValue);
      setFieldValue("milestones[0].end_date", endDateValue);
    },
    [setFieldValue]
  );

  return (
    <DateField
      calendarType="single-date"
      hasError={!DATES_OPTIONAL && hasError}
      className={className}
      placeholder="Due Date"
      dateDisplayProps={{ label: "Due Date" }}
      calendarProps={{
        type: "single-date",
        value: value,
        onChange: handleChange,
      }}
      onClick={() => {
        setFieldTouched("milestones[0].start_date");
        setFieldTouched("milestones[0].end_date");
      }}
    />
  );
};

export default TransactionDateInput;
