import React, { useCallback, useMemo } from "react";
import lodashGet from "lodash/get";

import DateField from "@/components/input/DateField";
import { SingleDateValue } from "@/components/input/Calendar";

import { useContractForm } from "../../OfferUpdateForm.config";
import { DATES_OPTIONAL } from "../../OfferUpdateForm.config";

const MilestoneDateInput: React.FC<{
  milestoneIndex: number;
  className?: string;
}> = ({ milestoneIndex, className }) => {
  const { values, setFieldValue, touched, setFieldTouched } = useContractForm();

  const endDateFieldKey = `milestones[${milestoneIndex}].end_date`;
  const isEndDateTouched = lodashGet(touched, endDateFieldKey, false);
  const endDateValue = lodashGet(values, endDateFieldKey, "");
  const endDateHasError = isEndDateTouched && !endDateValue;
  const hasError = endDateHasError;

  const value = useMemo(
    () => (endDateValue ? new Date(endDateValue) : null),
    [endDateValue]
  );

  //--------------------------

  const handleChange = useCallback(
    (value?: SingleDateValue) => {
      const endDateValue = value ? value.toISOString() : null;
      setFieldValue(endDateFieldKey, endDateValue);
    },
    [setFieldValue, endDateFieldKey]
  );

  //--------------------------

  return (
    <DateField
      calendarType="single-date"
      placeholder="Due Date"
      dateDisplayProps={{ label: "Due Date" }}
      hasError={!DATES_OPTIONAL && hasError}
      className={className}
      calendarProps={{
        type: "single-date",
        value: value,
        onChange: handleChange,
      }}
      onClick={() => {
        setFieldTouched(endDateFieldKey);
      }}
    />
  );
};

export default MilestoneDateInput;
